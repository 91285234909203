/* Global Styles */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #e6f7f9; /* Soft winter blue */
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.App {
  text-align: center;
  background: #fffdf5; /* Creamy white */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 5%;
  max-width: 90%;
  border: 3px solid #f22f46; /* Festive red border */
  overflow-x: auto;
}

/* Header */
header h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #ffffff; /* White for readability */
  text-align: center;
}

/* Main Content */
main h2 {
  margin: 20px 0;
  font-size: 1.8rem;
}

main .count {
  font-size: 1.5rem;
  color: #217c2a; /* Christmas green */
  font-weight: bold;
}

/* Actions Section */
.actions {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.actions input,
.actions select {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

.actions button {
  padding: 10px 15px;
  font-size: 1rem;
  color: #fff;
  background-color: #f22f46; /* Christmas red */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.actions button:hover {
  background-color: #b51f32; /* Darker red */
}

/* Festive Banner */
.festive-banner {
  background: linear-gradient(to right, #f22f46, #ffcc33); /* Red and gold gradient */
  padding: 10px;
  text-align: center;
  color: white;
  font-family: 'Pacifico', cursive; /* Decorative font */
  font-size: 1.8rem;
  border-bottom: 5px solid #217c2a; /* Green border */
}

.flashing-lights {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.light {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  animation: flash 1.5s infinite;
}

.red {
  background-color: red;
  animation-delay: 0s;
}

.green {
  background-color: green;
  animation-delay: 0.5s;
}

.yellow {
  background-color: yellow;
  animation-delay: 1s;
}

.blue {
  background-color: blue;
  animation-delay: 1.5s;
}

@keyframes flash {
  0%, 100% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
}

/* Cookie Stats */
.cookie-stats ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.cookie-stats li {
  margin: 5px 0;
  font-size: 1.1rem;
  text-align: left;
}

/* Responsive Table */
.table-container {
  overflow-x: auto; /* Allow horizontal scrolling for smaller screens */
}

table {
  width: 100%; /* Full width for container */
  border-collapse: collapse; /* Merge borders */
  margin: 10px 0;
}

th, td {
  border: 1px solid #ddd; /* Add border to cells */
  padding: 8px; /* Add padding inside cells */
  text-align: left; /* Align text to the left */
}

th {
  background-color: #f2f2f2; /* Light gray background for header */
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f9f9f9; /* Alternate row color */
}

@media (max-width: 768px) {
  th, td {
    font-size: 0.6rem;
  }
}

/* Countdown Section */
.countdown {
  margin: 10px 0;
  padding: 10px;
  background-color: #ffe4e1; /* Soft pink background */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
}

/* Footer */
footer {
  margin-top: 20px;
  font-size: 0.8rem;
  color: #666;
  text-align: center;
}

/* Image Popup */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup img {
  max-height: 90%;
  max-width: 90%;
  border-radius: 10px;
}

.popup-close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1.5rem;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
}

/* Mobile Adjustments */
@media (max-width: 480px) {
  .App {
    padding: 5%;
    border-width: 2px;
  }

  header h1 {
    font-size: 1.5rem;
  }

  main h2 {
    font-size: 1.2rem;
  }

  .countdown {
    font-size: 0.9rem;
    padding: 8px;
  }

  .actions button {
    font-size: 0.9rem;
    padding: 8px 10px;
  }
}

.cookie-stats {
  margin-top: 20px;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cookie-stats h3 {
  margin-bottom: 10px;
  color: #217c2a; /* Christmas green */
}

.cookie-stats ul {
  list-style-type: none;
  padding: 0;
}

.cookie-stats li {
  margin: 5px 0;
  font-size: 1.1rem;
}